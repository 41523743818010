'use client';
import React from 'react';
import Link from 'next/link';
import { mergeClassLists } from 'tailwind-classlist';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';
import { classes } from './utils';

export default function HeaderLink({ className = '', ...props }) {
  const pathname = usePathname();
  const isActive = pathname === props.href;

  return (
    <Link
      className={mergeClassLists(
        classes.link,
        isActive ? classes.active : classes.inactive,
        className
      )}
      {...props}
    />
  );
}

HeaderLink.propTypes = {
  href: PropTypes.string.isRequired,
};
