import React from 'react';
import HeaderLink from '@/containers/HeaderContainer/HeaderLink';
import { LINKS } from '../utils';

export default function HeaderNavigation() {
  return (
    <>
      {LINKS.map((link) => (
        <HeaderLink key={link.href} href={link.href}>
          {link.name}
        </HeaderLink>
      ))}
    </>
  );
}
