import { theme } from '@/utils/theme';

export const classes = {
  header:
    'relative h-20 px-4 flex items-center bg-white border-b border-grey-4 shadow-header',
  logoWrapper: 'relative h-[67%] ml-2 mr-8',
  logo: 'h-full w-auto',
};

export const styles = {
  header: {
    '&': {
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: 'start',
      },
    },
  },
};

export const LINKS = [
  { name: 'Home', href: '/' },
  { name: 'Company', href: '/company/' },
  { name: 'Investors', href: '/investors/' },
  { name: 'Press Releases', href: '/press-releases/' },
  { name: 'Financial Figures', href: '/financial-figures/' },
];
