import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import HeaderLink from '@/containers/HeaderContainer/HeaderLink';
import { LINKS } from '../utils';
import { classes } from './utils';

export default function DrawerNavigation({ onClose }) {
  return (
    <Drawer
      open
      PaperProps={{ className: classes.drawer }}
      anchor="right"
      onClose={onClose}>
      {LINKS.map((link) => (
        <HeaderLink
          key={link.href}
          className={classes.link}
          href={link.href}
          onClick={() => onClose()}>
          {link.name}
        </HeaderLink>
      ))}
    </Drawer>
  );
}

DrawerNavigation.propTypes = {
  onClose: PropTypes.func.isRequired,
};
