'use client';
import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import HeaderNavigation from './HeaderNavigation';
import DrawerNavigation from './DrawerNavigation';
import Box from '@mui/material/Box';
import Logo from './condor_flash_logo.png';
import { theme } from '@/utils/theme';
import { classes, styles } from './utils';

export default function HeaderContainer() {
  const showBurger = useMediaQuery(theme.breakpoints.down('md'));
  const [isDrawerOpen, toggleDrawer] = useState(false);

  return (
    <>
      {isDrawerOpen && <DrawerNavigation onClose={() => toggleDrawer(false)} />}
      <Box className={classes.header} sx={styles.header}>
        <Link href="/" className={classes.logoWrapper}>
          <Image src={Logo} alt="Condor logo" className={classes.logo} />
        </Link>
        {showBurger ? (
          <IconButton onClick={() => toggleDrawer((isOpen) => !isOpen)}>
            <MenuIcon />
          </IconButton>
        ) : (
          <HeaderNavigation />
        )}
      </Box>
    </>
  );
}

HeaderContainer.propTypes = {};
